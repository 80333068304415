import React from 'react';
import "./Collection.scss"; 

const products = {
    marble: ["Agaria White", "Morwad White", "Toronto", "Pink", "Green", "Banswara White"],
    granite: [
        "Jet Black", 
        "Sira Grey", 
        "Silver Grey", 
        "Cheema Pink", 
        "Rozy Pink", 
        "Platinum White", 
        "Jeerawal White", 
        "S. White", 
        "Black Markino", 
        "R. Black", 
        "Majestic Black", 
        "Marie Gold", 
        "Himalayan Blues", 
        "Paradiso Black", 
        "Paradiso Brown", 
        "Ruby Red",
        "Juprana Pink", 
        "Chilli Red", 
        "Madurai Gold", 
        "Kashmir White", 
        "Tan Brown",
        "Black Galaxy",
        "Steel Grey",
        "Leather Brown",
        "Crystal Blue",
        "Diamond Brown",
        "Noor Black",
    ],
    tiles: ["Ceramic", "Vitrified", "Full Body Vitrified", "Double Charge"],
    groutsAndAdhesives: ["Epoxy Grout", "Cement Grout", "Tile Adhesive", "Grout Mixture", "Tile Bonders"],
    others: ["Tile Cleaners", "Grout Spacers", "Marble Chips"]
};

const Collection = () => {
    return (
        <div className="product-collection">
            {/* <h2>Our Collection</h2> */}
            {Object.entries(products).map(([category, items]) => (
                <div className="category" id={category} key={category} style={{marginTop:'100px'}}>
                    <h3>{category.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</h3>
                    <div className='flex-cc'>
                        <div className='category-container'>
                            <ul className='product-gallery product-list'>
                                {
                                    items.map((item, index) => (
                                        <li key={index} className='product-img list-item'>
                                            {item}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Collection;
