import React from 'react';
import PropTypes from 'prop-types';
import './StarRating.scss';

const StarRating = ({ rating }) => {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const isHalfStar = rating - index === 0.5;
    const isFullStar = rating - index >= 1;
    return (
      <span key={index} className="star">
        {isFullStar ? '★' : isHalfStar ? '⯨' : '☆'}
      </span>
    );
  });

  return <div className="star-rating">{stars}</div>;
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default StarRating;
