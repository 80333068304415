import React from "react";
import { Helmet } from "react-helmet";
import { blogHostname, webTitle } from "./Constants";
import logo from '../assets/svg/logo_circle.svg';


// const HomePage =() =>{
//     window.scrollTo({ top: 0, behavior:'smooth'});

//     return (
//         <div style={{display:'flex', justifyContent:'center'}}>
//             <Helmet>
//                 <title>Home- {webTitle}</title>
//             </Helmet>
//             <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
//             </div>
//         </div>
//     )
// }

// export default HomePage;


// import React from 'react';
import './HomePage.scss'; // Make sure to create a corresponding CSS file for styling
import { Button, ButtonTakeMeToUrl } from "../components/button/Button";
import Carousel from "../components/ui/Carousel/Carousel";
import StarRating from "../components/ui/StarRating/StarRating";
import Tag from "../components/Tag/Tag";

const HomePage = () => {
  return (
    // <div style={{display:'flex', justifyContent:'center'}}>
    //     <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
                <div className="homepage">
            {/* Hero Section */}
            {/* <section className="hero">
                <img src="/assets/images/marblequarry_hdr.jpg" alt="Hero" className="hero-image" />
                    <h1 style={{color:'#24422a'}}>Transform Your Space with Premium Stones</h1>
                    <div style={{background:"#c6e7cc"}}>
                    </div>
                    <ButtonTakeMeToUrl
                        takeMeTo={{
                            to:'contact',
                        }}
                    >
                        Explore Our Collection
                    </ButtonTakeMeToUrl>
            </section> */}

            {/* About Us */}
            {/* <section className="about-us">
                <h2>About Us</h2>
                <p>We provide the finest granites, marbles, tiles, and quartz to elevate your home and office spaces.</p>
                <img src="path-to-your-team-image.jpg" alt="Our Team" className="about-image" />
            </section> */}

            {/* Hero Section */}
            <section className="hero">
                <a href='/collection'>
                    <img src="/assets/images/premium_marblequarry_white.jpg" alt="Hero" className="hero-image" />
                </a>
                {/* <div style={{position:'absolute', bottom:'50%'}}>
                    <div style={{width:'100vw', height:'100%', backgroundImage:'linear-gradient(0deg, rgba(0,0,0,0.3), rgba(255, 255, 255, 0.9))'}}>
                        <div style={{marginTop:150}}>
                            <h1 style={{color:'#24422a'}}>Transform Your Space with Premium Stones</h1>
                        </div> */}
                        {/* <ButtonTakeMeToUrl
                            takeMeTo={{
                                to:'contact',
                            }}
                        >
                            Explore Our Collection
                        </ButtonTakeMeToUrl> */}
                    {/* </div> */}
                {/* </div> */}
                
            </section>
            {/* Featured Products */}
            <section className="featured-products" style={{marginTop:80}}>
                <h2>Featured Products</h2>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{maxWidth:'1000px', width:'100%'}}>
                        <div className="product-gallery">
                            <div className="product">
                                <img src="/assets/images/featured_products/1.jpg" alt="Product 1" className="product-img"/>
                                <p style={{textWrap:'nowrap'}}>Indian Marble</p>
                            </div>
                            <div className="product">
                                <img src="/assets/images/featured_products/2.jpg" alt="Product 2" className="product-img"/>
                                <p style={{textWrap:'nowrap'}}>Indian Granite</p>
                            </div>
                            <div className="product">
                                <img src="/assets/images/featured_products/3.jpg" alt="Product 3" className="product-img"/>
                                <p style={{textWrap:'nowrap'}}>Indian Granite</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div style={{display:'flex', justifyContent:'center', alignItems:'flex-start', marginTop:'80px'}}>
                <div style={{ maxWidth:'1400px', width:'100%'}}>
                    <div className="grid-2-1fr">
                        <div className="flex-cc" style={{alignItems:'flex-start'}}>
                            {/* Customer Testimonials */}
                            <section className="testimonials" style={{width:'100%'}}>
                                <h2>Testimonials</h2>
                                {/* Carousel */}
                                <section style={{height:'400px', border:'2px solid #eee', borderRadius:'15px'}}>
                                    <Carousel 
                                        slideChangeButton={false}
                                        slides={[
                                            <blockquote className="flex-cc flex-col h-full"> 
                                                <div className="flex-cc flex-col">
                                                    <Tag label='Google Review' bgColor="#1c271d" fontSize="1.2rem" style={{position:'absolute', left:0 }}/>
                                                    <StarRating rating={5} />
                                                </div>
                                                <p>"This is the best place to buy marble, granite and tiles if you want great quality at the most reasonable prices and owners are absolutely trustworthy. They are all ears to your queries and they ensure a prompt response. Would highly recommend shopping here!"</p>
                                                <cite>Jaya Sharma</cite>
                                            </blockquote>,
                                            <blockquote className="flex-cc flex-col h-full">
                                                <div className="flex-cc flex-col">
                                                    <Tag label='Google Review' bgColor="#1c271d" fontSize="1.2rem" style={{position:'absolute', left:0 }}/>
                                                    <StarRating rating={5} />
                                                </div>
                                                <p>"V.good"</p>
                                                <cite>Manoj Kumar</cite>
                                            </blockquote>
                                        ]}
                                    />
                                </section>
                            </section>
                        </div>    
                        <div className="flex-cc" style={{alignItems:'flex-start'}}>
                            {/* Blog Section */}
                            <section className="blog">
                                <div className="flex-cc">
                                    <h2>Latest Blog Posts</h2>
                                    <i className="bi bi-box-arrow-up-right" style={{fontSize:'0.7rem', marginLeft:'10px'}}></i>
                                    
                                </div>
                                <div className="blog-posts">
                                    <article className="post-article">
                                        <ButtonTakeMeToUrl className="read-more light-button w-full"
                                            takeMeTo={{
                                                to:"posts/how-to-choose-the-perfect-marble-for-your-home",
                                                hostname: blogHostname
                                            }} 
                                            style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', paddingRight:'20px', alignItems:'flex-start'}}
                                        >
                                            <h3>How to Choose the Perfect Marble for Your Home</h3>
                                            <p>Discover tips and tricks for selecting the perfect marble...</p>
                                        </ButtonTakeMeToUrl>
                                    </article>

                                    <article className="post-article">
                                        
                                        <ButtonTakeMeToUrl className="read-more light-button w-full"
                                            takeMeTo={{
                                                to: 'posts/top-10-granite-trends-for-2024',
                                                hostname: blogHostname
                                            }}
                                            style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', paddingRight:'20px', alignItems:'flex-start'}}
                                        >
                                            <h3>Top 10 Granite Trends for 2024</h3>
                                            <p>Check out the top trends in 2024 for your space...</p>
                                        </ButtonTakeMeToUrl>
                                    </article>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact Information */}
            {/* <section className="contact">
                <h2>Contact Us</h2>
                <form className="contact-form">
                <label>
                    Name:
                    <input type="text" name="name" />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" />
                </label>
                <label>
                    Message:
                    <textarea name="message"></textarea>
                </label>
                <button type="submit">Send</button>
                </form> */}
                {/* <div className="contact-details">
                <p>Phone: +91-1234567890</p>
                <p>Email: info@yourwebsite.com</p>
                <p>Address: 123 Your Street, Faridabad, Haryana, India</p>
                </div> */}
                {/* <div className="map"> */}
                {/* Embed Google Map */}
                {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.835434509374!2d144.9630579153169!3d-37.81410797975171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d8c3e1b0d8e!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1611814412345!5m2!1sen!2sau"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe> */}
                {/* </div>    */}
                {/* </section> */}

            {/* Footer */}
            {/* <footer className="footer">
                <div className="footer-links">
                <a href="/about">About Us</a>
                <a href="/products">Products</a>
                <a href="/blog">Blog</a>
                <a href="/contact">Contact</a>
                </div>
                <div className="social-media">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
                </div>
            </footer> */}
            </div>
    //     </div>
    // </div>
  );
};

export default HomePage;
